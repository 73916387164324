<template>
  <b-card title="Sister Finansial Participant List">
    <b-col cols="12 row text-left" class="p-0 mt-1">
      <b-col cols="col-2 md-2 sm-6 ml-1 mb-2">
        <b-button
          variant="success"
          class="btn waves-effect waves-float waves-light btn-success"
          v-b-modal.form-export-modal
        >
          Export Sister Finansial Participants
        </b-button>
      </b-col>
    </b-col>

    <b-row class="justify-content-start">
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="status">Status:</label>
          <v-select
            id="status"
            label="name"
            v-model="filter.status"
            multiple
            :options="status"
            :reduce="(status) => status.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="start-date">Start Date:</label>
          <flat-pickr
            id="start-date"
            class="form-control"
            v-model="startDateRange"
            :config="{
              dateFormat: 'Y-m-d',
              mode: 'range',
            }"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="start-date">Submit Date:</label>
          <flat-pickr
            id="start-date"
            class="form-control"
            v-model="submitDateRange"
            :config="{
              dateFormat: 'Y-m-d',
              mode: 'range',
            }"
          />
        </div>
      </b-col>
    </b-row>

    <Table
      :result="result"
      :participants="participants"
      :is-loading="isLoading"
      :get-data="getData"
      :current-page="currentPage"
      :showId="showId"
      :approve-loan="approveLoan"
      :reject-loan="rejectLoan"
      :show-item="showItem"
      :modalData="modalData"
    />

    <Modal
      :get-history="getHistory"
      :get-columns="getColumns"
      :history="histories"
      :columns="columns"
      :filterParams="filter"
      :startDateFilterParams="filter.start_time_date_range.start_date"
      :endDateFilterParams="filter.start_time_date_range.end_date"
      :submitStartDateFilterParams="filter.submit_time_date_range.start_date"
      :submitEndDateFilterParams="filter.submit_time_date_range.end_date"
    />
  </b-card>
</template>

<script>
import Table from "@/components/bank/TableListParticipant.vue";
import Modal from "@/components/bank/ModalParticipant.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { successNotification, errorNotification } from "@/auth/utils";
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BSpinner,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
export default {
  components: {
    Table,
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormInput,
    flatPickr,
    vSelect,
    Modal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
    startDateRange: {
      handler: _.debounce(function () {
        if (this.startDateRange) {
          const dates = this.startDateRange.split(" to ");
          if (dates.length === 2) {
            this.filter.start_time_date_range.start_date = dates[0];
            this.filter.start_time_date_range.end_date = dates[1];
          }
        }
      }, 300),
    },
    submitDateRange: {
      handler: _.debounce(function () {
        if (this.submitDateRange) {
          const dates = this.submitDateRange.split(" to ");
          if (dates.length === 2) {
            this.filter.submit_time_date_range.start_date = dates[0];
            this.filter.submit_time_date_range.end_date = dates[1];
          }
        }
      }, 300),
    },
  },
  data() {
    return {
      currentPage: 1,
      isLoading: false,
      result: [],
      participants: [],
      link: "",
      isLoadingExport: false,
      formPayload: {
        name: "",
      },
      showId: null,
      status: [
        { value: "on_process", name: "On Process" },
        { value: "approved", name: "Approved" },
        { value: "rejected", name: "Rejected" },
      ],
      filter: {
        submit_time_date_range: {
          start_date: "",
          end_date: "",
        },
        start_time_date_range: {
          start_date: "",
          end_date: "",
        },
      },
      startDateRange: null,
      submitDateRange: null,
      validations: "",
      modalData: {},
      histories: [],
      columns: [],
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData(page) {
      this.isLoading = true;
      this.currentPage = page;
      const queryParams = { ...this.filter, page };

      delete queryParams.start_time_date_range;
      delete queryParams.submit_time_date_range;

      if (this.filter.start_time_date_range.start_date && this.filter.start_time_date_range.end_date) 
      {
        queryParams["start_time_date_range[start_date]"] = this.filter.start_time_date_range.start_date;
        queryParams["start_time_date_range[end_date]"] = this.filter.start_time_date_range.end_date;
      }
      
      if (this.filter.submit_time_date_range.start_date && this.filter.submit_time_date_range.end_date) 
      {
        queryParams["submit_time_date_range[start_date]"] = this.filter.submit_time_date_range.start_date;
        queryParams["submit_time_date_range[end_date]"] = this.filter.submit_time_date_range.end_date;
      }

      queryParams.bank_uuid = this.$route.params.uuid;

      this.$http
        .get("/api/v1/admin/bank-loan", {
          params: queryParams,
        })
        .then((response) => {
          this.result = response.data.data.meta;
          this.participants = response.data.data.items;
          this.currentPage = response.data.data.meta.currentPage;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getHistory() {
      this.$http
        .get(
          `/api/v1/admin/export/history/bank-loans?filter_key=${this.$route.params.uuid}`
        )
        .then((response) => {
          this.histories = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getColumns() {
      this.$http
        .get("/api/v1/admin/export/column?type=bank-loans")
        .then((response) => {
          this.columns = response.data.data.shift().columns;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    approveLoan(item) {
      this.isLoading = true;
      this.$http
        .post(`/api/v1/admin/bank-loan/${item.uuid}/approve`)
        .then((response) => {
          this.$bvModal.hide("participant-history-modal");
          this.getData(this.currentPage);
          successNotification(this, "Success", "Loan successfully approved");
          this.cleanUpForm();
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
          }
          this.isLoading = false;
        });
    },
    rejectLoan(item) {
      this.isLoading = true;
      this.$http
        .post(`/api/v1/admin/bank-loan/${item.uuid}/reject`)
        .then((response) => {
          this.$bvModal.hide("participant-history-modal");
          this.getData(this.currentPage);
          successNotification(this, "Success", "Loan successfully rejected");
          this.cleanUpForm();
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
          }
          this.isLoading = false;
        });
    },
    showItem(item) {
      this.$router.push(`/banks/${item.uuid}/detail-pinjaman`);

      // this.showId = item.uuid;

      // // this.formPayload = {
      // //     name: item.name,
      // // };

      // // Call the API to fetch the data for the modal
      // this.$http
      //   .get(`/api/v1/admin/bank-loan/${item.uuid}`)
      //   .then((response) => {
      //     this.modalData = response.data.data;
      //     this.$bvModal.show("participant-history-modal");
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
